import React from "react";
import { Trans } from "@lingui/macro";
import SEO from "components/Common/SEO";

import Footer from "components/Footer/Footer";
import { getPageTitle } from "lib/legacy";

import arbitrumIcon from "img/ic_arbitrum_16.svg";
import avalancheIcon from "img/ic_tlos_16.svg";

import "./TestnetFaucet.css";
import ExternalLink from "components/ExternalLink/ExternalLink";
import { ARBITRUM, AVALANCHE, TELOS_TESTNET, TELOS_MAINNET } from "config/chains";
import { t } from "@lingui/macro";

import { Web3Provider } from "@ethersproject/providers";
import { ethers } from "ethers";

const abi = '[ { "inputs": [], "name": "mint", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [ { "internalType": "address", "name": "_token", "type": "address" }, { "internalType": "uint256", "name": "_maxMintPerUser", "type": "uint256" } ], "stateMutability": "nonpayable", "type": "constructor" }, { "inputs": [], "name": "admin", "outputs": [ { "internalType": "address", "name": "", "type": "address" } ], "stateMutability": "view", "type": "function" }, { "inputs": [ { "internalType": "address", "name": "", "type": "address" } ], "name": "hasMinted", "outputs": [ { "internalType": "bool", "name": "", "type": "bool" } ], "stateMutability": "view", "type": "function" }, { "inputs": [], "name": "maxMintPerUser", "outputs": [ { "internalType": "uint256", "name": "", "type": "uint256" } ], "stateMutability": "view", "type": "function" }, { "inputs": [], "name": "token", "outputs": [ { "internalType": "contract IERC20", "name": "", "type": "address" } ], "stateMutability": "view", "type": "function" } ]'

const charmAddress = '0xCD4dd5B46dacdD187ADbF5b2906faeD2a07B4e8F';
const ethAddress = '0x05976095Bb7202A50F0CB67AD35Dd172F2e13559';
const btcAddress = '0x24C8c31dDfaCdc5c829a9761C3886857D2DA4Ad8';



// const provider = new ethers.providers.JsonRpcProvider("https://testnet.telos.net/evm", 41);
// const contract = new ethers.Contract(btcAddress, abi, provider);


async function mintCHARM() {
  // Prompt user to connect to MetaMask
  await window.ethereum.request({ method: 'eth_requestAccounts' });
  // Create a new provider using the injected ethereum object
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // Retrieve the connected signer (account)
  const signer = provider.getSigner();

  const contract = new ethers.Contract(charmAddress, abi, signer);
  try {
    const result = await contract.mint();
    console.log('Mint transaction result:', result);
  } catch (error) {
    console.error('Error minting tokens:', error);
  }
}

async function mintETH() {
  // Prompt user to connect to MetaMask
  await window.ethereum.request({ method: 'eth_requestAccounts' });
  // Create a new provider using the injected ethereum object
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // Retrieve the connected signer (account)
  const signer = provider.getSigner();

  const contract = new ethers.Contract(ethAddress, abi, signer);
  try {
    const result = await contract.mint();
    console.log('Mint transaction result:', result);
  } catch (error) {
    console.error('Error minting tokens:', error);
  }
}

async function mintBTC() {
  // Prompt user to connect to MetaMask
  await window.ethereum.request({ method: 'eth_requestAccounts' });
  // Create a new provider using the injected ethereum object
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // Retrieve the connected signer (account)
  const signer = provider.getSigner();

  const contract = new ethers.Contract(btcAddress, abi, signer);
  try {
    const result = await contract.mint();
    console.log('Mint transaction result:', result);
  } catch (error) {
    console.error('Error minting tokens:', error);
  }
}

const NETWORK_ICONS = {
  [ARBITRUM]: arbitrumIcon,
  [AVALANCHE]: avalancheIcon,
};

const NETWORK_ICON_ALTS = {
  [ARBITRUM]: "Arbitrum Icon",
  [AVALANCHE]: "Avalanche Icon",
};

export default function Ecosystem() {
  const gmxPages = [

    {
      title: "Testnet CHARM",
      function: mintCHARM,
      link: "https://omnidex.finance/",
      linkLabel: "omnidex.finance",
      about: t` `,
      chainIds: [AVALANCHE],
    },

    {
      title: "Testnet BTC",
      function: mintBTC,
      link: "https://omnidex.finance/",
      linkLabel: "omnidex.finance",
      about: t` `,
      chainIds: [AVALANCHE],
    },

    {
      title: "Testnet ETH",
      function: mintETH,
      link: "https://omnidex.finance/",
      linkLabel: "omnidex.finance",
      about: t` `,
      chainIds: [AVALANCHE],
    }

  ];

  return (
    <SEO title={getPageTitle("Ecosystem Projects")}>
      <div className="default-container page-layout">
        <div>
          <div className="section-title-block">
            <div className="section-title-icon" />
            <div className="section-title-content">
              <div className="Page-title">
                <Trans>Testnet Faucet</Trans>
              </div>
              <div className="Page-description">
                <Trans>Mint testnet tokens and start trading on OmniDex perpetuals!  </Trans>
                <p>Mint Testnet TLOS Here: <a href="https://app.telos.net/testnet/developers" target="_blank"> TLOS Testnet Faucet</a></p>
              </div>
            </div>
          </div>
          <div className="DashboardV2-projects">
            {gmxPages.map((item) => {
              const linkLabel = item.linkLabel ? item.linkLabel : item.link;
              return (
                <div className="App-card" key={item.title}>
                  <div className="App-card-title">
                    {item.title}
                    <button onClick={item.function}>Mint</button>
                  </div>
                  <div className="App-card-divider"></div>
                  <div className="App-card-content">
                    <div className="App-card-row">
                      <div className="label">
                        <Trans>Click the "mint" button to receive testnet tokens</Trans>
                      </div>
                      <div>{item.about}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </SEO>
  );
}
