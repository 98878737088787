import { BigNumber, BigNumberish, ethers } from "ethers";

export function bigNumberify(n: BigNumberish) {
  try {
    return BigNumber.from(n);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("bigNumberify error", e);
    return undefined;
  }
}


export function convertBigIntToSignificantFigures(number, significantFigures) {
  // rounds a BigNumber integer to a specified number of sig figures
  const numberString = number.toString();
  const decimalIndex = numberString.indexOf('.');
  const integerPart = decimalIndex >= 0 ? numberString.slice(0, decimalIndex) : numberString;
  const decimalPart = decimalIndex >= 0 ? numberString.slice(decimalIndex + 1) : '';

  const integerSignificantFigures = Math.max(0, significantFigures - decimalPart.length);
  const roundedInteger = Math.round(Number(integerPart) / 10 ** (integerPart.length - integerSignificantFigures));

  let roundedNumber = roundedInteger.toString();
  // console.log("integerPart ",integerPart," decimalPart ",decimalPart," integerSignificantFigures ",integerSignificantFigures," roundedNumber ",roundedNumber)  // JJ
  roundedNumber = roundedNumber.padEnd(integerPart.length, '0');

  return BigNumber.from(roundedNumber);
}


export function roundBigNumber(num: BigNumber, significantFigures: number): BigNumber {
  // Convert the BigNumber to a string
  let numString = num.toString();

  // Find the position of the first non-zero digit
  const firstNonZeroDigit = numString.search(/[1-9]/);

  // Calculate the number of digits to keep
  const digitsToKeep = Math.min(
    significantFigures,
    numString.length - firstNonZeroDigit
  );

  // Round the number to the specified number of digits
  const rounded = parseFloat(numString.substr(0, firstNonZeroDigit + digitsToKeep)).toFixed(digitsToKeep);

  // Convert the rounded string back to a BigNumber and return it
  return BigNumber.from(rounded);
}

export function expandDecimals(n: BigNumberish, decimals: number): BigNumber {
  // @ts-ignore
  return bigNumberify(n).mul(bigNumberify(10).pow(decimals));
}

export const trimZeroDecimals = (amount: string) => {
  if (parseFloat(amount) === parseInt(amount)) {
    return parseInt(amount).toString();
  }
  return amount;
};

export const limitDecimals = (amount: BigNumberish, maxDecimals?: number) => {
  let amountStr = amount.toString();
  if (maxDecimals === undefined) {
    return amountStr;
  }
  if (maxDecimals === 0) {
    return amountStr.split(".")[0];
  }
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    let decimals = amountStr.length - dotIndex - 1;
    if (decimals > maxDecimals) {
      amountStr = amountStr.substr(0, amountStr.length - (decimals - maxDecimals));
    }
  }
  return amountStr;
};

export const padDecimals = (amount: BigNumberish, minDecimals: number) => {
  let amountStr = amount.toString();
  const dotIndex = amountStr.indexOf(".");
  if (dotIndex !== -1) {
    const decimals = amountStr.length - dotIndex - 1;
    if (decimals < minDecimals) {
      amountStr = amountStr.padEnd(amountStr.length + (minDecimals - decimals), "0");
    }
  } else {
    amountStr = amountStr + ".0000";
  }
  return amountStr;
};

export const formatAmount = (
  amount: BigNumberish | undefined,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean,
  defaultValue?: string
) => {
  if (!defaultValue) {
    defaultValue = "...";
  }
  if (amount === undefined || amount.toString().length === 0) {
    return defaultValue;
  }
  if (displayDecimals === undefined) {
    displayDecimals = 4;
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  if (displayDecimals !== 0) {
    amountStr = padDecimals(amountStr, displayDecimals);
  }
  if (useCommas) {
    return numberWithCommas(amountStr);
  }
  return amountStr;
};

export const formatKeyAmount = (
  map: any,
  key: string,
  tokenDecimals: number,
  displayDecimals: number,
  useCommas?: boolean
) => {
  if (!map || !map[key]) {
    return "...";
  }

  return formatAmount(map[key], tokenDecimals, displayDecimals, useCommas);
};

export const formatArrayAmount = (
  arr: any[],
  index: number,
  tokenDecimals: number,
  displayDecimals?: number,
  useCommas?: boolean
) => {
  if (!arr || !arr[index]) {
    return "...";
  }

  return formatAmount(arr[index], tokenDecimals, displayDecimals, useCommas);
};

export const formatAmountFree = (amount: BigNumberish, tokenDecimals: number, displayDecimals?: number) => {
  if (!amount) {
    return "...";
  }
  let amountStr = ethers.utils.formatUnits(amount, tokenDecimals);
  amountStr = limitDecimals(amountStr, displayDecimals);
  return trimZeroDecimals(amountStr);
};

export const parseValue = (value: string, tokenDecimals: number) => {
  const pValue = parseFloat(value);

  if (isNaN(pValue)) {
    return undefined;
  }

  value = limitDecimals(value, tokenDecimals);
  const amount = ethers.utils.parseUnits(value, tokenDecimals);
  return bigNumberify(amount);
};

export function numberWithCommas(x: BigNumberish) {
  if (!x) {
    return "...";
  }

  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}
