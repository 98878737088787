import { ARBITRUM, AVALANCHE, TELOS_TESTNET, TELOS_MAINNET } from "./chains";

export const SUBGRAPH_URLS = {
  [ARBITRUM]: {
    stats: "",
    referrals: "https://subgraph.omnidex.finance/subgraphs/name/perpetuals-referrals",
    nissohVault: "https://api.thegraph.com/subgraphs/name/nissoh/gmx-vault",
  },

  [AVALANCHE]: {
    stats: "",
    referrals: "https://subgraph.omnidex.finance/subgraphs/name/perpetuals-referrals",
  },

  [TELOS_TESTNET]: {
    stats: "",
    referrals: "https://subgraph.omnidex.finance/subgraphs/name/perpetuals-referrals",
  },

  [TELOS_MAINNET]: {
    stats: "https://subgraph.omnidex.finance/subgraphs/name/perpetuals-stats",
    referrals: "https://subgraph.omnidex.finance/subgraphs/name/perpetuals-referrals",
  },

  common: {
    chainLink: "https://api.thegraph.com/subgraphs/name/deividask/chainlink",
  },
};
